import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import "../../Css/style.css";
import Footer from "../Common/footer";
import Left from "../Common/Left";
import useWindowDimensions from "../Common/useWindowDimensions";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { CompactPicker, GithubPicker } from "react-color";
import MultiSelect from "react-select";
const Settings = () => {
  const gridRef = useRef();
  const [projectlist, setprojectlist] = useState([]);
  const [project, setproject] = useState("");
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [userlist, setuserlist] = useState([]);
  const [user, setuser] = useState("");
  const [rolelist, setrolelist] = useState([]);
  const [role, setrole] = useState("");
  const { height, width } = useWindowDimensions();
  const [rowData, setRowData] = useState(null);
  const [saverowData, setSaveRowData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [msg, setmsg] = useState("");
  const [severity, setseverity] = useState("success");
  const [Menupos1, setMenupos1] = useState(false);
  const [selectedActionButton, setselectedActionButton] = useState("");
  const [hasGridData, sethasGridData] = React.useState(false);
  const [isCheckedShowRoleOption, setisCheckedShowRoleOption] = useState(false);
  const [isCheckedshowUerOption, setisCheckedshowUerOption] = useState(false);
  const [isCheckedshowButtonOption, setisCheckedshowButtonOption] =
    useState(false);
  const [isCheckedshowFieldTypeOption, setisCheckedshowFieldTypeOption] =
    useState(false);
  const [
    isCheckedshowEditViewFieldOption,
    setisCheckedshowEditViewFieldOption,
  ] = useState(false);
  const [accesslevel, setaccesslevel] = useState({
    showRoleOption: false,
    showUerOption: false,
    showButtonOption: false,
    showFieldTypeOption: false,
    showEditViewFieldOption: false,
  });

  useEffect(() => {
    localStorage.setItem("isShowRole", false);
    localStorage.setItem("isShowUser", false);
    localStorage.setItem("isShowButton", false);
    localStorage.setItem("isShowFieldType", false);
    localStorage.setItem("isEditViewField", false);
    populateProject();
    populateUser();
    populateRole();
  }, []);

  useEffect(() => {
    localStorage.setItem("isShowRole", isCheckedShowRoleOption);
  }, [isCheckedShowRoleOption]);

  useEffect(() => {
    localStorage.setItem("isShowUser", isCheckedshowUerOption);
  }, [isCheckedshowUerOption]);

  useEffect(() => {
    localStorage.setItem("isShowButton", isCheckedshowButtonOption);
  }, [isCheckedshowButtonOption]);

  useEffect(() => {
    localStorage.setItem("isShowFieldType", isCheckedshowFieldTypeOption);
  }, [isCheckedshowFieldTypeOption]);

  useEffect(() => {
    localStorage.setItem("isEditViewField", isCheckedshowEditViewFieldOption);
  }, [isCheckedshowEditViewFieldOption]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const populateProject = () => {
    const options = {
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "",
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    Axios.get(
      process.env.REACT_APP_API_DOMAIN_URL +
        "/api/v1/taskmanagement/data/role/getallprojects",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )

      .then((res) => {
        setprojectlist(res.data);
      })
      .catch((err) => {
       // console.log(err);
      });
  };
  function handleFontColorChange(color, event) {
    gridOptions.rowData[this.indx].fontColor = color.hex;
    saveAllData();
  }

  const FontcolorPalette = [
    "#f44336",
    "#FFFFFF",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
  ];

  function handleBackColorChange(color, event) {
    gridOptions.rowData[this.indx].backgroundColor = color.hex;
    saveAllData();
  }

  const backcolorPalette = [
    "#f44336",
    "#FFFFFF",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
  ];

  const handleChangeProject = (event) => {
    localStorage.setItem("SettingProjectId", event.target.value);
    showProjectFields(event.target.value, role);
  };
  const populateUser = () => {
    const options = {
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "",
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    Axios.get(
      process.env.REACT_APP_API_DOMAIN_URL +
        "/api/v1/taskmanagement/data/user/userList",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )

      .then((res) => {
        setuserlist(res.data);
        // console.log("User List: ",res.data)
      })
      .catch((err) => {
       // console.log(err);
      });
  };
  const handleChangeUser = (event) => {
    localStorage.setItem(
      "SettingUser",
      event?.map((item, index) => {
        return item.UserId;
      })
    );
    //setuser(event.target.value);
    setuser(event);
  };
  const populateRole = () => {
    const options = {
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "",
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    Axios.get(
      process.env.REACT_APP_API_DOMAIN_URL +
        "/api/v1/taskmanagement/data/role/rolelist",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )

      .then((res) => {
        setrolelist(res.data);
       // console.log("Role", res.data);
      })
      .catch((err) => {
       // console.log(err);
      });
  };

  const AddNewRole = (RoleName) => {
    if (localStorage.getItem("SettingUser") != null) {
    const userId_array = localStorage.getItem("SettingUser").split(",");
    const _userIds = userId_array?.map((item, index) => {
      return { userId: item };
    });

    const data = {
      description: RoleName,
      roleId: RoleName,
      users: _userIds,
      projects: [localStorage.getItem("SettingProjectId")],
      activities: [
        {
          name: RoleName,
        },
      ],
    };

    Axios.post(
      process.env.REACT_APP_API_DOMAIN_URL +
        "/api/v1/taskmanagement/data/role/createrole",
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )

      .then((res) => {
        localStorage.setItem("SettingRole", RoleName);
        setrole(RoleName);
       // console.log("RoleName:", RoleName);
        //setrolelist([]);
        populateRole();
      })
      .catch((err) => {
       // console.log(err);
      });
    }
  };

  const handleChangeRole = (event) => {
    localStorage.setItem("SettingRole", event.target.value);
    setrole(event.target.value);
    showProjectFields(project, event.target.value);
  };
  const columnDefs = [];
  const savemenuconfigData = [];
  const gridOptions = {
    frameworkComponents: {
      //colorbox: colorbox,
      colorbox: function (params) {
        return (
          <CompactPicker
            id={"fontcolor" + params.rowIndex}
            indx={params.rowIndex}
            colors={FontcolorPalette}
            color={params.data.fontColor}
            onChange={handleFontColorChange}
          />
        );
      },
      colorbox2: function (params) {
        return (
          <GithubPicker
            id={"backcolor" + params.rowIndex}
            indx={params.rowIndex}
            colors={backcolorPalette}
            showSwatch={params.data.backgroundColor}
            onChange={handleBackColorChange}
          />
        );
      },
    },
    defaultColDef: {
      sortable: true,
      filter: "setTextFilterParams",
      resizable: true,
      editable: true,
    },
    suppressRowClickSelection: true,
    rowSelection: "multiple",
    columnDefs: columnDefs,
    enableSorting: false,
    enableFilter: false,
    pagination: false,
    //rowHeight: 50,
    api: gridApi,
  };

  const showProjectFields = (prid, roleId) => {
    setRowData(null);
    const data = {
      projectId: prid,
      roleId: roleId,
    };
    const options = {
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "",
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    Axios.post(
      process.env.REACT_APP_API_DOMAIN_URL +
        "/api/v1/taskmanagement/data/config/menuconfig",
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((res) => {
        setproject(prid);
        sethasGridData(true);
        // console.log(res.data)
        const colDefs = columnDefs; //= gridOptions.api.getColumnDefs();
        colDefs.length = 0;
        const _accesslevel = {
          showRoleOption: res.data.showRoleOption,
          showUerOption: res.data.showUerOption,
          showButtonOption: res.data.showButtonOption,
          showFieldTypeOption: res.data.showFieldTypeOption,
          showEditViewFieldOption: res.data.showEditViewFieldOption,
        };
        setaccesslevel(_accesslevel);
        setisCheckedShowRoleOption(res.data.showRoleOption);
        localStorage.setItem("isShowRole", res.data.showRoleOption);
        setisCheckedshowUerOption(res.data.showUerOption);
        localStorage.setItem("isShowUser", res.data.showUerOption);
        setisCheckedshowButtonOption(res.data.showButtonOption);
        localStorage.setItem("isShowButton", res.data.showButtonOption);
        setisCheckedshowFieldTypeOption(res.data.showFieldTypeOption);
        localStorage.setItem("isShowFieldType", res.data.showFieldTypeOption);
        setisCheckedshowEditViewFieldOption(res.data.showEditViewFieldOption);
        localStorage.setItem(
          "isEditViewField",
          res.data.showEditViewFieldOption
        );

        colDefs.push({
          headerName: "Question Name",
          field: "questionName",
          editable: false,
          sortable: false,
          width: 150,
          lockPosition: true,
          pinned: width <= 1024 ? "" : "left",
          cellStyle: { color: "Black", backgroundColor: "white" },
        });
        colDefs.push({
          headerName: "Header",
          field: "label",
          editable: false,
          sortable: false,
          cellStyle: { color: "Black", backgroundColor: "white" },
          cellRenderer: function (params) {
            var rowIndex = params.rowIndex;
            var input = document.createElement("input");
            input.type = "text";
            input.value = params.data.label;
            input.addEventListener("change", function (event) {
              event.preventDefault();
              //console.log(input.value);

              saverowData.rowsToDisplay[rowIndex].data.label = input.value;
              setSaveRowData(saverowData);

              saveAllData();
            });
            return input;
          },
        });

        colDefs.push({
          headerName: "CanEdit",
          field: "edit",
          editable: false,
          width: 100,
          hide: res.data.showEditViewFieldOption ===true? false:true,
          cellStyle: { color: "blue", backgroundColor: "white" },
          cellRenderer: function (params) {
            var rowIndex = params.rowIndex;

            var lbl = document.createElement("label");
            lbl.className = "switch";
            var input = document.createElement("input");
            input.type = "checkbox";

            if (params.data.CanEdit == "True") {
              input.checked = true;
            } else {
              input.checked = false;
            }
            input.addEventListener("click", function (event) {
              //console.log(this.checked);
              saverowData.rowsToDisplay[rowIndex].data.edit = this.checked;
              setSaveRowData(saverowData);

              saveAllData();
            });
            lbl.appendChild(input);
            var spn = document.createElement("span");
            spn.className = "slider round";
            lbl.appendChild(spn);
            return lbl;
          },
        });

        colDefs.push({
          headerName: "Show/Hide",
          field: "show",
          editable: false,
          width: 100,
          cellStyle: { color: "blue", backgroundColor: "white" },
          cellRenderer: function (params) {
            var rowIndex = params.rowIndex;
            //console.log("////////////////////////////////////")
            // console.log(params.data.ShowHide);

            var lbl = document.createElement("label");
            lbl.className = "switch";
            var input = document.createElement("input");
            input.type = "checkbox";

            if (params.data.show == true) {
              input.checked = true;
            } else {
              input.checked = false;
            }
            input.addEventListener("click", function (event) {
              saverowData.rowsToDisplay[rowIndex].data.show = this.checked;
              setSaveRowData(saverowData);

              saveAllData();
            });
            lbl.appendChild(input);
            var spn = document.createElement("span");
            spn.className = "slider round";
            lbl.appendChild(spn);
            return lbl;
          },
        });
        colDefs.push({
          headerName: "Font Color",
          field: "fontColor",
          width: 270,
          cellRenderer: "colorbox",
          editable: false,
        });

        colDefs.push({
          headerName: "Back Color",
          field: "backgroundColor",
          width: 270,
          cellRenderer: "colorbox2",
          editable: false,
        });

        colDefs.push({
          headerName: "Type",
          field: "type",
          editable: false,
          width: 180,
          hide: res.data.showFieldTypeOption ===true? false:true,
          //lockPosition: true,
          //pinned:width <= 1024 ?'':'left',
          cellRenderer: function (params) {
            var rowIndex = params.rowIndex;
            var Column = params.eGridCell.attributes.colId;
            var WeldGridData = gridOptions.rowData;

            var eSelect = document.createElement("select");
            var eOption = document.createElement("option");
            var eOption1 = document.createElement("option");
            var eOption2 = document.createElement("option");
            var eOption3 = document.createElement("option");
            var eCalOption=document.createElement("option");
            var eLinkOption=document.createElement("option");
            var eRateoption=document.createElement("option");
            
            eOption.text = "Select Type";
            eOption.value = "";
            eSelect.appendChild(eOption);
            eOption1.text = "Text";
            eOption1.value = "string";
            if (params.data.type == "string") {
              eOption1.selected = true;
            }
            eSelect.appendChild(eOption1);
            eOption2.text = "Number";
            eOption2.value = "Number";
            if (params.data.type == "Number") {
              eOption2.selected = true;
            }
            eSelect.appendChild(eOption2);
            eOption3.text = "Dropdown";
            eOption3.value = "Dropdown";
            // console.log(params.data.type)
            if (params.data.Type == "Dropdown") {
              eOption3.selected = true;
            }
            eSelect.appendChild(eOption3);

            eCalOption.text = "Calendar";
            eCalOption.value = "calendar";
            if (params.data.Type == "Dropdown") {
              eCalOption.selected = true;
            }
            eSelect.appendChild(eCalOption);

            eLinkOption.text = "Link";
            eLinkOption.value = "link";
            if (params.data.Type == "Dropdown") {
              eLinkOption.selected = true;
            }
            eSelect.appendChild(eLinkOption);

            eRateoption.text = "Rating";
            eRateoption.value = "rating";
            if (params.data.Type == "Dropdown") {
              eRateoption.selected = true;
            }
            eSelect.appendChild(eRateoption);

            eSelect.addEventListener("change", function (event) {
              saverowData.rowsToDisplay[rowIndex].data.type = eSelect.value;
              setSaveRowData(saverowData);
              saveAllData();
            });
            return eSelect;
          },
        });

        colDefs.push({
          headerName: "fieldOrder",
          field: "fieldOrder",
          editable: false,
          sortable: false,
          width: 150,
          hide: true,
        });

        gridOptions.api.setColumnDefs(colDefs);

        setRowData(res.data.fieldsetting);
      })
      .catch((err) => {
       // console.log(err);
      });
  };
  const getRowNodeId = (data) => {
    return data.Header;
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    showProjectFields(params.columnApi, role);
    setSaveRowData(params.api.rowModel);
  };

  const saveAllData = () => {
    const AllowActionButton_array = localStorage.getItem("SettingAllowActionButton")!=null? localStorage
      .getItem("SettingAllowActionButton")
      .split(",") : [];

    const _buttons = AllowActionButton_array?.map((item, index) => {
      return {
        name: item,
        fontColor: "",
        backgroundColor: "",
        gid: localStorage.getItem("SettingProjectId"),
      };
    });
    const userId_array = localStorage.getItem("SettingUser")!=null? localStorage.getItem("SettingUser").split(","):[];
    const _userIds = userId_array?.map((item, index) => {
      return { userId: item };
    });

    savemenuconfigData.length = 0;
    gridRef.current.api.forEachNode(printNode);
    const data = {
      gid: localStorage.getItem("SettingProjectId"),
      buttons: _buttons,
      fieldsetting: savemenuconfigData,
      name: null,//localStorage.getItem("SettingUser"),
      roleId: localStorage.getItem("SettingRole"),
      users: _userIds,
      showRoleOption: localStorage.getItem("isShowRole"),
      showUerOption: localStorage.getItem("isShowUser"),
      showButtonOption: localStorage.getItem("isShowButton"),
      showFieldTypeOption: localStorage.getItem("isShowFieldType"),
      showEditViewFieldOption: localStorage.getItem("isEditViewField"),
      projectId: localStorage.getItem("SettingProjectId"),
    };
    //console.log("Save Data", data);
    Axios.post(
      process.env.REACT_APP_API_DOMAIN_URL +
        "/api/v1/taskmanagement/data/config/savemenuconfig",
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((res) => {
        setseverity("success");
        setmsg("Data Added successfully");
        setOpen(true);

        //console.log('Save',res.data)
      })
      .catch((err) => {
       // console.log(err);
        setseverity("error");
      });
  };

  const printNode = (node, index) => {
    savemenuconfigData.push(node.data);
  };

  const AllowActionButton = [
    {
      value: "Approve",
      label: "Approve",
    },
    {
      value: "Hold",
      label: "Hold",
    },
    {
      value: "Deny",
      label: "Deny",
    },
    {
      value: "Deny And Send Email",
      label: "Deny And Send Email",
    },
  ];

  const handleChangeAllowActionButton = (event) => {
    localStorage.setItem(
      "SettingAllowActionButton",
      event?.map((item, index) => {
        return item.value;
      })
    );
    setselectedActionButton(event);
  };
  const handleChangeRoleName = (event) => {
    event.preventDefault();
    //console.log(event.target.value);

    if (event.target.value !== "") {
      AddNewRole(event.target.value);
    }
  };
  const handleOnChangeShowRoleOption = () => {
    setisCheckedShowRoleOption(!isCheckedShowRoleOption);
  };

  const handleOnChangeshowUerOption = () => {
    setisCheckedshowUerOption(!isCheckedshowUerOption);
  };

  const handleOnChangeshowButtonOption = () => {
    setisCheckedshowButtonOption(!isCheckedshowButtonOption);
  };

  const handleOnChangeshowFieldTypeOption = () => {
    setisCheckedshowFieldTypeOption(!isCheckedshowFieldTypeOption);
  };

  const handleOnChangeshowEditViewFieldOption = () => {
    setisCheckedshowEditViewFieldOption(!isCheckedshowEditViewFieldOption);
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {msg}
        </Alert>
      </Snackbar>
      <div className="container-scroller">
        <Left isShow={Menupos1 ? "sidebar-icon-only" : ""} />
        <div className="container-fluid page-body-wrapper">
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <div className="select-box">
                <h3>Settings Your Pages</h3>
                <ul>
                  <li>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="ddlProject"
                      value={project}
                      onChange={handleChangeProject}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Select Project</em>
                      </MenuItem>
                      {projectlist?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.gid}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </li>
                  {accesslevel.showRoleOption === true && (
                    <>
                  <li>
                    <input
                      type="text"
                      name="fname"
                      placeholder="Role"
                      onBlur={handleChangeRoleName}
                    />
                  </li>

                  <li>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="ddlRole"
                      value={role}
                      onChange={handleChangeRole}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Select Role</em>
                      </MenuItem>
                      {rolelist?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.roleId}>
                            {item.description}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </li>
                  </>
                  )}
                  {accesslevel.showButtonOption === true && (
                  <li>
                    <MultiSelect
                      id="AllowActionButton"
                      isMulti
                      placeholder="Select Action Button"
                      value={selectedActionButton} // set selected value
                      options={AllowActionButton} // set list of the data
                      onChange={handleChangeAllowActionButton} // assign onChange function
                    />
                  </li>
                  )}
                  {accesslevel.showUerOption === true && (
                  <li className="tump">
                    <MultiSelect
                      id="ddlUser"
                      isMulti
                      placeholder="Select User"
                      value={user} // set selected value
                      options={userlist?.map((item, index) => {
                        return {
                          value: item.fristName + " " + item.lastName,
                          UserId: item.userId,
                          text: item.fristName + " " + item.lastName,
                          photoURL: item.photoURL,
                          sortName: item.fristName[0] + item.lastName[0],
                        };
                      })} // set list of the data
                      onChange={handleChangeUser} // assign onChange function
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {e.photoURL != undefined && e.photoURL.length > 0 ? (
                            <>
                              {
                                <img
                                  className="nav-profile-img mr-2"
                                  alt=""
                                  src={e.photoURL}
                                />
                              }
                            </>
                          ) : (
                            <><div className="usersortcode" > {e.sortName}</div>
                                                        </>
                          )}

                          <span style={{ marginLeft: 5 }}>{e.text}</span>
                        </div>
                      )}
                    />
                  </li>
                  )}

                  </ul>
                  
                  <ul  className="new-class">
                  {accesslevel.showRoleOption === true && (
                  <li>
                    <div>
                      <input
                        type="checkbox"
                        id="chkshowRoleOption"
                        name="showRoleOption"
                        value="showRoleOption"
                        checked={isCheckedShowRoleOption}
                        onChange={handleOnChangeShowRoleOption}
                      />
                     &nbsp; Show Role Option
                    </div>
                  </li>
                  )}

                  {accesslevel.showUerOption === true && (
                  <li>
                    <div>
                      <input
                        type="checkbox"
                        id="chkshowUerOption"
                        name="showUerOption"
                        value="showUerOption"
                        checked={isCheckedshowUerOption}
                        onChange={handleOnChangeshowUerOption}
                      />
                     &nbsp; Show Uer Option
                    </div>
                  </li>
                  )}

                  {accesslevel.showButtonOption === true && (
                  <li>
                    <div>
                      <input
                        type="checkbox"
                        id="chkshowButtonOption"
                        name="showButtonOption"
                        value="showButtonOption"
                        checked={isCheckedshowButtonOption}
                        onChange={handleOnChangeshowButtonOption}
                      />
                     &nbsp; Show Button Option
                    </div>
                  </li>
                  )}
                  {accesslevel.showFieldTypeOption === true && (
                  <li>
                    <div>
                      <input
                        type="checkbox"
                        id="chkshowFieldTypeOption"
                        name="showFieldTypeOption"
                        value="showFieldTypeOption"
                        checked={isCheckedshowFieldTypeOption}
                        onChange={handleOnChangeshowFieldTypeOption}
                      />
                      &nbsp; Show Field Type Option
                    </div>
                  </li>
                  )}

                  {accesslevel.showEditViewFieldOption === true && (
                  <li>
                    <div>
                      <input
                        type="checkbox"
                        id="chkshowEditViewFieldOption"
                        name="showEditViewFieldOption"
                        value="showEditViewFieldOption"
                        checked={isCheckedshowEditViewFieldOption}
                        onChange={handleOnChangeshowEditViewFieldOption}
                      />
                      &nbsp; Show Edit View Field Option
                    </div>
                  </li>
                  )}
                </ul>
              </div>

              <div className="table-setting">
                <div className="row">
                  <div className="col-xl-12 col-sm-9 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body px-0 overflow-auto">
                        <div className="table-responsive">
                          <div
                            className="ag-theme-alpine"
                            id="mn"
                            style={{ height: height - 210 }}
                          >
                            <AgGridReact
                              ref={gridRef}
                              gridOptions={gridOptions}
                              immutableData={true}
                              getRowNodeId={getRowNodeId}
                              rowData={rowData}
                              onGridReady={onGridReady}
                            ></AgGridReact>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {hasGridData ==true  && <Button
                  variant="contained"
                  color="primary"
                  onClick={() => gridApi.applyTransaction({ add: [{}] })}
                >
                  Add New
                </Button>} */}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Settings;
