import React from 'react';

import '../../Js/off-canvas.js';
import '../../Js/misc.js';

function Footer() {
    return (
        <>
            
        </>
    );
}

export default Footer;