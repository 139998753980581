import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(

  <BrowserRouter>
    <App />
  </BrowserRouter>
  ,
  document.getElementById('root')
);

//ReactDOM.render(React.createElement(MyjQueryReactComponent, { context: $('body') }), document.getElementById('root'));
//});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
