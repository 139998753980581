import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import Axios from "axios";
import "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import close from "../../images/close.png";
import CustomDateComponent from "./customDateComponent";
import CustomEditorComponent from "./customEditorComponent";
import NumericCellEditor from "./numericCellEditor.jsx";
import Rating from "./Rating";
import useWindowDimensions from "./useWindowDimensions";

function DashboardListing() {
  const [ListingData, setListingData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [Assign, setAssign] = useState("");
  const { id } = useParams();
  let history = useHistory();
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [assignList, setassignList] = useState([]);
  const [AssignDateMsg, setAssignDateMsg] = useState("");
  const [AssignMsg, setAssignMsg] = useState("");
  const [AssignListMsg, setAssignListMsg] = useState("");
  const [projectname, setprojectname] = useState("");
  const [open, setOpen] = React.useState(false);
  const [msg, setmsg] = useState("");
  const [severity, setseverity] = useState("success");
  const [Menupos, setMenupos] = useState(false);
  const [ButtonList, setButtonList] = useState([]);
  const [IsAssignAllowed, setIsAssignAllowed] = useState(false);
  const [UpdateListMsg, setUpdateListMsg] = useState("");
  const [CommentOpen, setCommentOpen] = useState(false);
  const [ButtonStatus, setButtonStatus] = useState("");
  const [Comments, setComments] = useState("");
  const [CommentMsg, setCommentMsg] = useState("");
  const { height, width } = useWindowDimensions();
  const [PopUpButtonClickStatus, setPopUpButtonClickStatus] = useState(false);
  const [Menupos1, setMenupos1] = useState(false);
  const [AssignUser, setAssignUser] = useState("");

  const ShowMenu1 = () => {
    if (Menupos1) {
      setMenupos1(false);
    } else {
      setMenupos1(true);
    }
  };

  const handleDateChange = (date) => {
    //console.log(date);
    setSelectedDate(date);
  };

  const frameworkComponents = {
    agDateInput: CustomDateComponent,
    customEditor: CustomEditorComponent,
    Rating: Rating,
    CustomNumericCellEditor: NumericCellEditor,
  };

  useEffect(() => {
    getListingData(null);
    getInitial();
    getProjectName();
    getProjectRole();
  }, [id]);

  const NumberTextboxCellRenderer = (params) => {
    var rowIndex = params.rowIndex;
    var input = document.createElement("input");
    input.type = "text";
    input.value = params.value;
    input.addEventListener("change", function (event) {
      event.preventDefault();
      const re = /^[0-9\b]+$/;
      if (!re.test(event.target.value)) {
        return false;
      }
    });
    return input;
  };

  const DropdownCellRenderer = (params) => {
    var rowIndex = params.rowIndex;
    var eSelect = document.createElement("select");
    var eOption = document.createElement("option");
    eOption.text = "Select Type";
    eOption.value = "";
    eSelect.appendChild(eOption);
    const CellData = params.rowProps[rowIndex].fieldDetails[params.cellIndex];
    const _gid=params.rowProps[rowIndex].gid
    const Datalist = CellData.dropDownData;
    if(Datalist!=null){
      Datalist.map((i, indx) => {
        var eOption1 = document.createElement("option");
        eOption1.text = i.name;
        eOption1.value = i.name;
        eSelect.appendChild(eOption1);
      });
    }
    
    eSelect.value = params.value;
    eSelect.disabled = CellData.editable == true ? false : CellData.editable;
    eSelect.addEventListener("change", function (event) {
      const cellValue = eSelect.value;
      const RowData = CellData;
      RowData.value = cellValue;
      RowData.valueChange = true;
      saveAllData(RowData,_gid);
    });
    return eSelect;
  };

  const LinkCellRenderer = (params) => {
    return `<span class="SpanAnchor">${params.value}</span>`;
  };
  const checkboxCellRenderer = () => {
    return "";
  };
  const statusCellRenderer = (params) => {
    return `<div class=${params.value}>${params.value}</div>`;
  };
  const setNumberFilterParams = {
    buttons: ["clear"],
    allowedCharPattern: "\\d\\-\\,\\$",
    numberParser: function (text) {
      //console.log(text);
      return text == null ? null : parseInt(text);
    },
  };
  const setDateFilterParams = {
    buttons: ["clear"],
    comparator: function (filtervalue, cellvalue) {
      const dateval1 = moment(new Date(filtervalue)).format("DD/MM/YYYY");
      const dateval2 = moment(
        new Date(cellvalue.toString().split("T")[0])
      ).format("DD/MM/YYYY");
      if (dateval1 === dateval2) {
        return 0;
      }
      //console.log(dateval1)
      //console.log(dateval2)
    },
  };
  const setTextFilterParams = {
    buttons: ["clear"],
    suppressAndOrCondition: true,
    fiterOptions: ["contains", "notContains"],
  };

  function dateFormatter(params) {
    if (params.value.trim() === "") {
      return;
    } else {
      const dateval = new Date(params.value.toString().split("T")[0]);
      return moment(dateval).format("MM/DD/YYYY");
    }
    //console.log((params.data['Application Date']).toString().split('T')[0])
  }

  const gridOptions = {
    defaultColDef: {
      resizable: true,
    },
    sizeColumnsToFit: true,
    checkboxSelection: true,
    columnDefs: [
      {
        headerName: "",
        field: "id",
        rowDrag: false,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        lockPosition: true,
        pinned: width <= 1024 ? "" : "left",
        //pinned:'left',
        cellRenderer: checkboxCellRenderer,
        width: 15,
      },
      {
        headerName: "Name",
        field: "name",
        sortable: true,
        filter: "agTextColumnFilter",
        hide: true,
        filterParams: setTextFilterParams,
        pinned: width <= 1024 ? "" : "left",
      },
      {
        headerName: "PhD Institution",
        field: "PhD Institution",
        rowDrag: false,
        sortable: true,
        filter: "agTextColumnFilter",
        hide: true,
        filterParams: setTextFilterParams,
      },
      {
        headerName: "PhD Year",
        field: "PhD Year",
        rowDrag: false,
        sortable: true,
        filter: "agTextColumnFilter",
        hide: true,
        filterParams: setTextFilterParams,
      },
      {
        headerName: "Application Date",
        field: "Application Date",
        rowDrag: false,
        sortable: true,
        filter: "agDateColumnFilter",
        hide: true,
        filterParams: setDateFilterParams,
        valueFormatter: dateFormatter,
      },

      {
        headerName: "Current Position",
        field: "Current Position",
        rowDrag: false,
        sortable: true,
        filter: true,
        filter: "agTextColumnFilter",
        hide: true,
        filterParams: setTextFilterParams,
      },
      {
        headerName: "Status",
        field: "Status",
        rowDrag: false,
        sortable: true,
        hide: true,
        filter: true,
        cellRenderer: statusCellRenderer,
      },
      {
        headerName: "Area of Expertise",
        field: "Area of Expertise",
        rowDrag: false,
        sortable: true,
        filter: "agTextColumnFilter",
        hide: true,
        filterParams: setTextFilterParams,
      },
      {
        headerName: "Citizenship",
        field: "Citizenship",
        rowDrag: false,
        sortable: true,
        filter: true,
        hide: true,
        filter: "agTextColumnFilter",
        filterParams: setTextFilterParams,
      },
      {
        headerName: "Region of Citizenship",
        field: "Region of Citizenship",
        rowDrag: false,
        sortable: true,
        filter: "agTextColumnFilter",
        hide: true,
        filterParams: setTextFilterParams,
      },
      {
        headerName: "Assignee",
        field: "Assignee",
        rowDrag: false,
        sortable: true,
        filter: "agTextColumnFilter",
        hide: true,
        filterParams: setTextFilterParams,
      },
      {
        headerName: "Due Date",
        field: "Due Date",
        rowDrag: false,
        sortable: true,
        filter: "agDateColumnFilter",
        hide: true,
        filterParams: setDateFilterParams,
        valueFormatter: dateFormatter,
      },
      {
        headerName: "Gender",
        field: "Gender",
        rowDrag: false,
        sortable: true,
        hide: true,
        filter: true,
      },
      {
        headerName: "Phd Subject",
        field: "Phd Subject",
        rowDrag: false,
        sortable: true,
        hide: true,
        filter: true,
        filter: "agTextColumnFilter",
        filterParams: setTextFilterParams,
      },
    ],
    rowSelection: "multiple",
    domLayout: "autoHeight",
    api: gridApi,
    onCellClicked: (e) => {
      const field = e.colDef.field;
      const colIndex = e.columnApi
        .getAllColumns()
        ?.findIndex((col) => col.getColDef().field === field);
      const celldata =
        e.columnApi.getAllColumns()[colIndex].colDef.cellRendererParams
          .rowProps[e.rowIndex].fieldDetails[colIndex - 1];
      if (celldata.type != "link" && celldata.editable == false) {
        history.push(`/Userdetails/${e.data.id}`);
      } else if (celldata.type == "link") {
        window.open(celldata.linkURL, "_blank", "noopener,noreferrer");
      }
    },
  };

  const gridOptionsMobile = {
    defaultColDef: {
      resizable: true,
    },
    sizeColumnsToFit: true,
    checkboxSelection: true,
    columnDefs: [
      {
        headerName: "",
        field: "id",
        rowDrag: false,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        lockPosition: true,
        cellRenderer: checkboxCellRenderer,
        width: 15,
      },
      {
        headerName: "Name",
        field: "name",
        sortable: true,
        filter: "agTextColumnFilter",
        hide: true,
        filterParams: setTextFilterParams,
      },
      {
        headerName: "PhD Institution",
        field: "PhD Institution",
        rowDrag: false,
        sortable: true,
        filter: "agTextColumnFilter",
        hide: true,
        filterParams: setTextFilterParams,
      },
      {
        headerName: "PhD Year",
        field: "PhD Year",
        rowDrag: false,
        sortable: true,
        filter: "agTextColumnFilter",
        hide: true,
        filterParams: setTextFilterParams,
      },
      {
        headerName: "Application Date",
        field: "Application Date",
        rowDrag: false,
        sortable: true,
        filter: "agDateColumnFilter",
        hide: true,
        filterParams: setDateFilterParams,
        valueFormatter: dateFormatter,
      },

      {
        headerName: "Current Position",
        field: "Current Position",
        rowDrag: false,
        sortable: true,
        filter: true,
        filter: "agTextColumnFilter",
        hide: true,
        filterParams: setTextFilterParams,
      },
      {
        headerName: "Status",
        field: "Status",
        rowDrag: false,
        sortable: true,
        hide: true,
        filter: true,
        cellRenderer: statusCellRenderer,
      },
      {
        headerName: "Area of Expertise",
        field: "Area of Expertise",
        rowDrag: false,
        sortable: true,
        filter: "agTextColumnFilter",
        hide: true,
        filterParams: setTextFilterParams,
      },
      {
        headerName: "Citizenship",
        field: "Citizenship",
        rowDrag: false,
        sortable: true,
        filter: true,
        hide: true,
        filter: "agTextColumnFilter",
        filterParams: setTextFilterParams,
      },
      {
        headerName: "Region of Citizenship",
        field: "Region of Citizenship",
        rowDrag: false,
        sortable: true,
        filter: "agTextColumnFilter",
        hide: true,
        filterParams: setTextFilterParams,
      },
      {
        headerName: "Assignee",
        field: "Assignee",
        rowDrag: false,
        sortable: true,
        filter: "agTextColumnFilter",
        hide: true,
        filterParams: setTextFilterParams,
      },
      {
        headerName: "Due Date",
        field: "Due Date",
        rowDrag: false,
        sortable: true,
        filter: "agDateColumnFilter",
        hide: true,
        filterParams: setDateFilterParams,
        valueFormatter: dateFormatter,
      },
      {
        headerName: "Gender",
        field: "Gender",
        rowDrag: false,
        sortable: true,
        hide: true,
        filter: true,
      },
      {
        headerName: "Phd Subject",
        field: "Phd Subject",
        rowDrag: false,
        sortable: true,
        hide: true,
        filter: true,
        filter: "agTextColumnFilter",
        filterParams: setTextFilterParams,
      },
    ],
    rowSelection: "multiple",
    domLayout: "autoHeight",
    api: gridApi,
    onCellClicked: (e) => {
      const field = e.colDef.field;
      const colIndex = e.columnApi
        .getAllColumns()
        ?.findIndex((col) => col.getColDef().field === field);
      const celldata =
        e.columnApi.getAllColumns()[colIndex].colDef.cellRendererParams
          .rowProps[e.rowIndex].fieldDetails[colIndex - 1];
      if (celldata.type != "link" && celldata.editable == false) {
        history.push(`/Userdetails/${e.data.id}`);
      } else if (celldata.type == "link") {
        window.open(celldata.linkURL, "_blank", "noopener,noreferrer");
      }
    },
  };

  const getListingData = (params) => {
    setRowData(null);
    if (params === null && gridColumnApi === null) {
      return;
    } else {
      if (params === null) {
        params = gridColumnApi;
      }
    }
    const data = {};
    const options = {
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "",
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    Axios.post(
      process.env.REACT_APP_API_DOMAIN_URL +
        process.env.REACT_APP_API_DASHBOARD_URL,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )

      .then((res) => {
        const coldef = gridOptions.api.getColumnDefs();
        coldef.length = 0;
        coldef.push({
          headerName: "",
          field: "id",
          rowDrag: false,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          lockPosition: true,
          pinned: width <= 1024 ? "" : "left",
          cellRenderer: checkboxCellRenderer,
          width: 15,
          cellRendererParams: {
            rowProps: null,
            gid: id,
          },
        });
        var FieldArray = res.data[0].fieldDetails;
        var FlexCount = 0;
        if (FieldArray.length < 6) {
          FlexCount = 2;
        }

        FieldArray.map((i, indx) => {          
          if (i.name !== null) {
            var colDefinations;
            const IsPinned = indx == 0 ? "left" : "";
            if (i.type === "link") {
              colDefinations = {
                headerName: i.name,
                field: i.name,
                rowDrag: false,
                sortable: true,
                pinned: IsPinned,
                filter: "agTextColumnFilter",
                hide: true,
                flex: FlexCount,
                cellStyle: {
                  color: i.fontColor,
                  backgroundColor: i.backgroundColor,
                },
                filterParams: setTextFilterParams,
                cellRenderer: LinkCellRenderer,
                cellRendererParams: {
                  rowProps: res.data,
                  cellIndex: indx,
                  gid: res.data.gid,
                },
              };
            } else if (i.type === "date") {
              colDefinations = {
                headerName: i.name,
                field: i.name,
                editable: i.editable,
                rowDrag: false,
                sortable: true,
                pinned: IsPinned,
                filter: "agTextColumnFilter",
                hide: true,
                flex: FlexCount,
                cellStyle: {
                  color: i.fontColor,
                  backgroundColor: i.backgroundColor,
                },
                filterParams: setDateFilterParams,
                cellEditor: "customEditor",
                cellRendererParams: {
                  rowProps: res.data,
                  cellIndex: indx,
                  gid: res.data.gid,
                },
              };
            } else if (i.type === "number") {
              colDefinations = {
                headerName: i.name,
                field: i.name,
                editable: i.editable,
                rowDrag: false,
                sortable: true,
                pinned: IsPinned,
                filter: "agTextColumnFilter",
                hide: true,
                flex: FlexCount,
                cellStyle: {
                  color: i.fontColor,
                  backgroundColor: i.backgroundColor,
                },
                filterParams: setNumberFilterParams,
                cellEditor: "CustomNumericCellEditor",
                cellRendererParams: {
                  rowProps: res.data,
                  cellIndex: indx,
                  gid: res.data.gid,
                },
              };
            } else if (i.type === "dropdown") {
              colDefinations = {
                headerName: i.name,
                field: i.name,
                editable: false, // editable handle at cellRenderer level,
                rowDrag: false,
                sortable: true,
                pinned: IsPinned,
                filter: "agTextColumnFilter",
                hide: true,
                flex: FlexCount,
                cellStyle: {
                  color: i.fontColor,
                  backgroundColor: i.backgroundColor,
                },
                filterParams: setTextFilterParams,
                cellRenderer: DropdownCellRenderer,
                cellRendererParams: {
                  rowProps: res.data,
                  cellIndex: indx,
                  gid: res.data.gid,
                },
                cellEditorPopup: true,
              };
            } else if (i.type === "rating") {
              colDefinations = {
                headerName: i.name,
                field: i.name,
                editable: i.editable,
                rowDrag: false,
                sortable: false,
                pinned: IsPinned,
                hide: true,
                flex: FlexCount,
                cellStyle: {
                  color: i.fontColor,
                  backgroundColor: i.backgroundColor,
                },
                cellRenderer: "Rating",
                cellRendererParams: {
                  rowProps: res.data,
                  cellIndex: indx,
                  gid: res.data.gid,
                },
              };
            } else {
              colDefinations = {
                width: 120,
                headerName: i.name,
                field: i.name,
                editable: i.editable,
                rowDrag: false,
                sortable: true,
                pinned: IsPinned,
                filter: "agTextColumnFilter",
                hide: true,
                flex: FlexCount,
                cellStyle: {
                  color: i.fontColor,
                  backgroundColor: i.backgroundColor,
                },
                filterParams: setTextFilterParams,
                cellRendererParams: {
                  rowProps: res.data,
                  cellIndex: indx,
                  gid: res.data.gid,
                },
              };
            }
            coldef.push(colDefinations);
          }
        });

        gridOptions.api.setColumnDefs(coldef);
        setListingData(res.data);

        var students = [];
        var result = res.data.map(({ fieldDetails }) => fieldDetails);

        result[0].map((item, index) => {
          params.setColumnVisible(item.name, true);
        });
        result.map((item, index) => {
          let temp = [];
          let sn = {};
          sn["id"] = res.data[index].gid;
          item.map((it, iy) => {
            sn[it.name] = it.value;
            temp.push({
              [it.name]: it.value,
            });
          });
          temp.push({ id: res.data[index].gid });
          let k = Object.keys(temp[1]);
          let v = Object.values(temp[1]);
          students.push(sn);
        });
        setRowData(students);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    getListingData(params.columnApi);
  };

  const onCellValueChanged = (event) => {
    const RendererParams = event.colDef.cellRendererParams;
    const RowIndex = event.rowIndex;
    const cellValue = event.value;
    const RowData =
      RendererParams.rowProps[RowIndex].fieldDetails[RendererParams.cellIndex];
    const _gid=RendererParams.rowProps[RowIndex].gid;
    RowData.value = cellValue;
    RowData.valueChange = true;
    saveAllData(RowData,_gid);
  };

  const saveAllData = (props,gid) => {
   
    const data = {
      _id: null,
      gid: gid,
      taskName: null,
      applicantId: null,
      positionId: null,
      assignee: null,
      fieldDetails: [props],
    };
    Axios.post(
      process.env.REACT_APP_API_DOMAIN_URL +
        process.env.REACT_APP_API_POST_LISTING_SAVE,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((res) => {
        setseverity("success");
        setmsg("Data Added successfully");
        setOpen(true);
      })
      .catch((err) => {
        //console.log(err);
        setseverity("error");
      });
  };

  const getRowNodeId = (data) => {
    return data.id;
  };

  
  
  const getInitial = () => {
    const data = {};
    const options = {
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "",
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    };
    Axios.post(
      process.env.REACT_APP_API_DOMAIN_URL +
        process.env.REACT_APP_API_USER_LIST_URL,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )

      .then((res) => {
        setassignList(res.data);
      })
      .catch((err) => {
       // console.log(err);
      });
  };

  const SubmitAssign = () => {
    let flagA = true;
    let assignArr = [];
    let selectedNodes = gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);

    if (selectedDate === undefined || selectedDate === null) {
      setAssignDateMsg("Please choose a date");
      flagA = false;
    } else {
      setAssignDateMsg("");
    }
    if (AssignUser === null || AssignUser === "") {
      setAssignMsg("Please select an assigner");
      flagA = false;
    } else {
      setAssignMsg("");
    }
    if (selectedData.length === 0) {
      setAssignListMsg("Please select at least one record");
      flagA = false;
    } else {
      setAssignListMsg("");
    }
    if (flagA) {
      selectedData.map((item, index) => {
        assignArr.push({
          gid: item.id,
          assignee: AssignUser,
          dueDate: moment(selectedDate).format(),
        });
      });
      const options = {
        headers: {
          "cache-control": "no-cache",
          "Content-Type": "application/json;charset=utf-8",
          "Access-Control-Allow-Origin": "",
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      };
      Axios.post(
        process.env.REACT_APP_API_DOMAIN_URL +
          process.env.REACT_APP_API_ASSIGN_DATE_URL,
        assignArr,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )

        .then((res) => {
          setmsg("Assigned successfully");
          setOpen(true);
          getListingData(null);
          setSelectedDate(null);
          setAssign("");
          setAssignUser("");
          setassignList([]);
          getInitial();
          gridOptions.api.deselectAll();
        })
        .catch((err) => {
          //console.log(err);
          setseverity("error");
          setmsg("Some error has been occurred");
          setOpen(true);
        });
    }
  };

  const getProjectName = () => {
    const data = {
      gid: id,
    };

    Axios.post(
      process.env.REACT_APP_API_DOMAIN_URL +
        process.env.REACT_APP_API_GET_PROJECT_URL,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((res) => {
        setprojectname(res.data.name);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const ShowMenu = () => {
    if (Menupos) {
      setMenupos(false);
    } else {
      setMenupos(true);
    }
  };

  const getProjectRole = () => {
    const _ButtonList=[]
    const data = {
      //projectId: 1//id,
    };

    Axios.post(
      process.env.REACT_APP_API_DOMAIN_URL +
        //process.env.REACT_APP_API_POST_ACTIVITY_ROLE_LISTING_URL,
        process.env.REACT_APP_API_POST_ACTIVITY_ROLE_DASHBOARD_URL,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )

      .then((res) => {
        res.data.map(item => {
          const _lst={
            gid:item.gid,
            name:item.name,
            css:item.name,
            type:"Button"
          }
        _ButtonList.push(_lst)
        });
       
      if (_ButtonList.length > 0) {
        setIsAssignAllowed(true);
      }
      
      //setButtonList(rolelist);
      setButtonList(_ButtonList);
      })
      .catch((err) => {
        //console.log(err.response.status);
       // console.log(err.response.data);
      });
  };

  const OpenComment = (status) => {
    setCommentOpen(true);
    setButtonStatus(status);
    setPopUpButtonClickStatus(false);
    if(status=="Deny" || status=="Deny And Send Email"){
      setComments("");
     // setComments("This applicant is not suitable");
    }
    else{
      setComments("");
    }
  };

  const ChangeComments = (e) => {
    setComments(e.target.value);
  };

  const UpdateStatus = (status) => {
    let flagA = true;
    let assignArr = [];
    let selectedNodes = gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);
    if (selectedData.length === 0) {
      setUpdateListMsg("Please select at least one record");
      flagA = false;
    } else {
      setUpdateListMsg("");
    }

    if (flagA && PopUpButtonClickStatus == false) {
      setPopUpButtonClickStatus(true);

      selectedData.map((item, index) => {
        assignArr.push({
          gid: item.id,
          decision: status,
          notes: `${Comments}`,
          decisionDate: moment(),
        });
      });
      const options = {
        headers: {
          "cache-control": "no-cache",
          "Content-Type": "application/json;charset=utf-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("Token"),
        },
      };

      Axios.post(
        process.env.REACT_APP_API_DOMAIN_URL +
          process.env.REACT_APP_API_UPDATEDECISION_PROJECT_URL,
        assignArr,
        options
      )
        .then((response) => {
          setseverity("success");
          setCommentOpen(false);
          setComments("");
          getListingData(null);
          gridOptions.api.deselectAll();
          setmsg("Status updated successfully");
          setOpen(true);
          setPopUpButtonClickStatus(false);
        })
        .catch((error) => {
          //console.log(error);
        });
    }
  };
  const CloseBox = () => {
    setCommentOpen(false);
    setCommentMsg("");
    setUpdateListMsg("");
    setPopUpButtonClickStatus(false);
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {msg}
        </Alert>
      </Snackbar>
      <div className="container-scroller">
        <div class="container-fluid page-body-wrapper">
          <div id="theme-settings" class="settings-panel">
            <i class="settings-close mdi mdi-close"></i>
            <p class="settings-heading">SIDEBAR SKINS</p>
            <div class="sidebar-bg-options selected" id="sidebar-default-theme">
              <div class="img-ss rounded-circle bg-light border mr-3"></div>{" "}
              Default
            </div>
            <div class="sidebar-bg-options" id="sidebar-dark-theme">
              <div class="img-ss rounded-circle bg-dark border mr-3"></div> Dark
            </div>
            <p class="settings-heading mt-2">HEADER SKINS</p>
            <div class="color-tiles mx-0 px-4">
              <div class="tiles light"></div>
              <div class="tiles dark"></div>
            </div>
          </div>

          <div class="main-panel">
            <div class="try fixed-top content-wrapper pb-0">
              <article>
                <div class="top-box-line">
                  
                  <div class="page-header flex-wrap">
                    <div class="left50">
                      <h3>{projectname}</h3>
                    </div>
                  </div>

                  <div className="qw2 bbr d-flex">
                    <div className="submite5">
                      {ButtonList !== undefined && ButtonList.length > 0 ? (
                        ButtonList.map((item, index) => (
                          <Link
                            className={item.css}
                            key={index}
                            onClick={() => OpenComment(`${item.name}`)}
                            to={"#"}
                          >{`${item.name}`}</Link>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                    {CommentOpen && (
                      <div className="CommentMsg">
                        {UpdateListMsg !== "" && <label>{UpdateListMsg}</label>}
                        {CommentMsg !== "" && <label>{CommentMsg}</label>}
                        <div className="name">Notes</div>
                        <input
                          type="text"
                          value={Comments}
                          maxLength="150"
                          onChange={(e) => ChangeComments(e)}
                        />
                        <input
                          type="button"
                          value="Submit"
                          onClick={() => UpdateStatus(`${ButtonStatus}`)}
                        />

                        <div className="close-img">
                          <Link to={"#"} onClick={() => CloseBox()}>
                            <img src={close} alt="" />
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div class="clr"></div>
              </article>
            </div>

            <div class="content-wrapper top-pad-off pb-0">
              <div class="row">
                <div class="col-xl-12 col-sm-12 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body px-0 overflow-auto">
                      <div class="table-responsive">
                        <div
                          className="ag-theme-alpine"
                          id="mn"
                          style={{ height: height - 210 }}
                        >
                          {width > 1024 && (
                            <AgGridReact
                              gridOptions={gridOptions}
                              immutableData={true}
                              getRowNodeId={getRowNodeId}
                              rowData={rowData}
                              frameworkComponents={frameworkComponents}
                              alwaysShowHorizontalScroll={true}
                              alwaysShowVerticalScroll={true}
                              suppressColumnVirtualisation={true}
                              suppressRowVirtualisation={true}
                              onGridReady={onGridReady}
                              onCellValueChanged={onCellValueChanged.bind(this)}
                            ></AgGridReact>
                          )}
                          {width <= 1024 && (
                            <AgGridReact
                              gridOptions={gridOptionsMobile}
                              immutableData={true}
                              getRowNodeId={getRowNodeId}
                              rowData={rowData}
                              frameworkComponents={frameworkComponents}
                              onGridReady={onGridReady}
                              onCellValueChanged={onCellValueChanged.bind(this)}
                            ></AgGridReact>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default DashboardListing;
