import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";
import React, { useState } from "react";
import { Rating } from "react-simple-star-rating";
import "../../Css/rating.css";
import star from "../../images/star.jpg";
import star1 from "../../images/star1.jpg";
import star2 from "../../images/star2.jpg";
import star3 from "../../images/star3.jpg";
import star4 from "../../images/star4.jpg";
import star5 from "../../images/star5.jpg";

export default function MyComponent(props) {
  const [severity, setseverity] = useState("success");
  const [msg, setmsg] = useState("");
  const [open, setOpen] = React.useState(false);
  const [rating, setRating] = useState(0); // initial rating value
  const _StarValue = props.value;
  const IsEditable =
    props.rowProps[props.rowIndex].fieldDetails[props.cellIndex].editable;
  const handleRating = (rate) => {
    //console.log(rate);
    setRating(rate);
    const cellValue = rate;
    const RowData =
      props.rowProps[props.rowIndex].fieldDetails[props.cellIndex];
    RowData.value = cellValue;
    RowData.valueChange = true;
    saveAllData(RowData, props.rowProps[props.rowIndex].gid);
  };
  const saveAllData = (props, id) => {
    const data = {
      _id: null,
      gid: id,
      taskName: null,
      applicantId: null,
      positionId: null,
      assignee: null,
      fieldDetails: [props],
    };
    //console.log("Save Data", data);
    Axios.post(
      process.env.REACT_APP_API_DOMAIN_URL +
        process.env.REACT_APP_API_POST_LISTING_SAVE,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((res) => {
        //console.log(res);
        setseverity("success");
        setmsg("Data Added successfully");
        setOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setseverity("error");
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  return (
    <>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {msg}
        </Alert>
      </Snackbar>
      <div className="App">
        {IsEditable === true && (
          <Rating
            onClick={handleRating}
            ratingValue={rating}
            initialValue={props.value} /* Available Props */
          />
        )}
        {IsEditable === false && (
          <>
            {_StarValue == 0 && <img src={star} alt="star" />}
            {_StarValue == 1 && <img src={star1} alt="star1" />}
            {_StarValue == 2 && <img src={star2} alt="star2" />}
            {_StarValue == 3 && <img src={star3} alt="star3" />}
            {_StarValue == 4 && <img src={star4} alt="star4" />}
            {_StarValue == 5 && <img src={star5} alt="star5" />}
          </>
        )}
      </div>
    </>
  );
}
